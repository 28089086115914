@tailwind base;

@tailwind components;

@tailwind utilities;

:root {
  --primary: blue;
}

.btn-blue {
  background-color: var(--primary);
  @apply w-1/6 px-0 py-2 flex justify-center text-white border rounded;
}

.unit-item {
  background-color: #61dafb;
  @apply w-full px-0 py-2 flex justify-center text-white border rounded my-2;
}

.unit-box {
  border: 1px solid #e0e0e0;

  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.01);
  border-radius: 5px;
}
.custom-btn {
  background-color: white;
  border-color: var(--primary);
  border-width: 2px;
  color: var(--primary);
  flex-direction: row;
  align-items: flex-start;
  padding: 3px 40px;

  min-width: 60px;
  height: 40px;
  margin-right: 5px;

  border-radius: 33px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.active-custom-btn {
  background-color: var(--primary) !important;
  color: white !important;
}

.rc-slider-mark-text-active {
  margin-top: 5px;
}

.font-thin,
.font-normal {
  color: #bfbfbf;
}
.white {
  color: white;
}

.rc-slider-handle:active {
  border-color: var(--primary) !important;
  box-shadow: 0 0 5px var(--primary) !important;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: var(--primary) !important;
  /*box-shadow: 0 0 0 5px #4f46e5 !important;*/
}
/*.rc-slider-handle:focus {*/
/*  outline: none;*/
/*  box-shadow: none;*/
/*}*/
.rc-slider-handle:focus-visible {
  border-color: var(--primary) !important;
  box-shadow: 0 0 0 5px #4f46e5 !important;
}

.rc-slider-handle-click-focused:focus {
  border-color: var(--primary) !important;
  box-shadow: 0 0 0 5px var(--primary) !important;
}

.rc-slider-handle:hover {
  border-color: var(--primary) !important;
  box-shadow: 0 0 5px #7c75de !important;
}

.rc-slider-handle:active {
  border-color: var(--primary) !important;
  box-shadow: 0 0 5px #4f46e5 !important;
}

.rc-slider-handle {
  border: solid 2px var(--primary) !important;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: var(--primary) !important;
  box-shadow: 0 0 5px var(--primary) !important;
}

.icon-status-lot {
  width: 30px;
}

sup {
  font-weight: 200;
  font-size: 8;
}

.label-card {
  color: #727272;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 10px;
}

.img-unit-cover {
  max-width: calc(100% - 20px);
  margin: 10px;
}

.line-break {
  flex-basis: 100%; /* Forces a break in the line */
  height: 0; /* No actual height */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: none ; /* Reset or set to desired value */
  background-image: initial ; /* Reset or set to desired value */
  background-color: #113f5f ; /* Reset or set to desired value */
}

.svg-container {
  /*flex: 1;*/
  /*display: flex;*/
  /*justify-content: center;*/
  /*align-items: center;*/
  position: relative;
}

.svg-parent {
  /*display: flex;*/
  /*flex-direction: row;*/
  /*justify-content: center;*/
  /*align-items: flex-start;*/
}

.rotate-container {
  position: absolute;
  right: 0;
  width: 70px;
  height: 60px;
  background-color: white;
  border-radius: 5px;
  margin: 10px;
  padding: 10px;
}

@media (min-width: 850px) and (max-width: 1000px) {
  .left-section {
    width: 350px;
  }
}

@media (min-width: 1000px) and (max-width: 1200px) {
  .left-section {
    width: 400px;
    min-width: 400px;
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .left-section {
    width: 500px;
    min-width: 450px;
  }
}

@media (min-width: 1301px) and (max-width: 1400px) {
  .left-section {
    width: 600px;
    min-width: 450px;
  }
}

@media (min-width: 1401px) {
  .left-section {
    min-width: 500px;
  }
}

@media (min-width: 850px) and (max-width: 1000px) {
  .svg-container .svgFull {
    height: 80vh;
    margin-top: 10vh;
  }
}

@media (min-width: 1301px) {
  .svg-container .svgFull {
    height: 100vh;
  }
}

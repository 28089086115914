/*.ant-select-selection-search input {*/
/*  margin-top: 5px !important;*/
/*}*/

/*::placeholder {*/
/*  //margin-top: 10px !important;*/
/*  //color: blue !important;*/
/*  //font-size: 1.5em;*/
/*}*/

/*.NumberFormat {*/
/*  width: 100%;*/
/*  border-radius: 0px !important;*/
/*  padding: 10px !important;*/
/*  border: 1px solid #adadad !important;*/
/*}*/

/*.ant-collapse-item.collaps-header-form .ant-collapse-header,*/
/*.ant-collapse-item.collaps-header-form .ant-collapse-content-box {*/
/*  padding: 0;*/
/*}*/

/*.custom-form {*/
/*  margin-bottom: 32px !important;*/

/*  .ant-row {*/
/*    margin-bottom: 12px;*/
/*  }*/

/*  .ant-input {*/
/*    min-height: 40px;*/
/*  }*/
/*  .ant-form-item-control-input {*/
/*    min-height: 40px;*/
/*  }*/
/*  .ant-select-selection-placeholder {*/
/*    !*line-height: 42px !important;*!*/
/*  }*/
/*}*/

/*input.ant-input-number-input {*/
/*  text-align: right;*/
/*}*/

/*.custom-form .ant-form-item .ant-input-password input {*/
/*  border: 0px !important;*/
/*  padding: 0px !important;*/
/*}*/

/*.colorfull .ant-select-selector {*/
/*  padding: 0 !important;*/
/*}*/

/*.colorfull .ant-select-selection-item {*/
/*  padding-inline-end: 0px !important;*/
/*}*/

/*.colorfull .ant-select-arrow svg {*/
/*  fill: white !important;*/
/*}*/

/*.item-color-select {*/
/*  color: white;*/
/*  width: 100%;*/
/*  display: block;*/
/*  padding-left: 10px;*/
/*  border-radius: 3px;*/
/*}*/

.ant-form-item-title {
  font-weight: bold;
  font-size: 20px;
}

.customform-radio .ant-radio {
  align-self: flex-start;
  margin-top: 3px;
}

span.anticon.anticon-close.ant-tag-close-icon {
  color: black;
}
